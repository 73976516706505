// src/languages/LanguageSwitcher.js
import React, { useContext, useRef, useEffect } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (lang) => {
    setLanguage(lang); // Ensure this updates immediately
    closeDropdown();
  };

  const toggleDropdown = () => {
    dropdownRef.current.classList.toggle('show');
  };

  const closeDropdown = () => {
    dropdownRef.current.classList.remove('show');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="language-dropdown" ref={dropdownRef}>
      <div className="flag-wrapper" onClick={toggleDropdown}>
        <svg width="45" height="29" viewBox="0 0 512 512">
          <use href={`/flags.svg#icon-flag-${language}`} />
        </svg>
      </div>
      <div className="language-dropdown-content">
        <div className="language-item" onClick={() => handleLanguageChange('en')}>
          <svg width="30" height="20" viewBox="0 0 512 512">
            <use href="/flags.svg#icon-flag-en" />
          </svg>
          <span>English</span>
        </div>
        <div className="language-item" onClick={() => handleLanguageChange('fa')}>
          <svg width="30" height="20" viewBox="0 0 512 512">
            <use href="/flags.svg#icon-flag-fa" />
          </svg>
          <span>فارسی</span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
