export default async function fetchApi(url, method, body = null, includeAuth = true) {
    const baseUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:8000/'
        : 'https://api.platformidea.com/';

    const authToken = JSON.parse(localStorage.getItem('authTokens'));
    const headers = {
        'Content-Type': 'application/json'
    };

    if (includeAuth && authToken?.access) {
        headers.Authorization = 'Bearer ' + authToken.access;
    }

    const response = await fetch(baseUrl + url, {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : null,
        credentials: 'include'
    });

    const responseData = await response.json();

    return {
        status: response.status,
        data: responseData,
    };
}
