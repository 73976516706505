import React, { useState, useContext } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createContact } from '../../api/endpoints/ContactEndpoints';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../languages/contexts/LanguageContext';
import './Contact.css';

const ContactForm = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  
  const languageClass = language === 'fa' ? 'lang-fa-direction' : '';
  const positionClass = language === 'fa' ? 'right-position' : 'left-position';

  const [focused, setFocused] = useState({
    name: false,
    phone: false,
    email: false,
    message: false,
  });

  const handleFocus = (field) => {
    setFocused({ ...focused, [field]: true });
  };

  const handleBlur = (field, event) => {
    if (!event.target.value) {
      setFocused({ ...focused, [field]: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactData = {
        name: e.target.name.value,
        email: e.target.email.value,
        message: e.target.message.value,
    };
    
    try {
      const response = await createContact(contactData);
        if (response.status === 201) {
          toast.success(response.data.message, {
            className: `toast-success ${language === 'fa' ? 'toast-fa' : ''}`,
          });
            e.target.reset();
        } else {
            toast.error(response.data.message, {
              className: `toast-error ${language === 'fa' ? 'toast-fa' : ''}`,
            });
        }
    } catch (error) {
        toast.error('Internal Error. Please try again.', {
            className: 'toast-error',
        });
    }
  };


  const formSectionProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: 300,
  });

  const contactInfoProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: 600,
  });

  return (
    <div className="contact__outer-container">
      <div className={`contact__form-container ${languageClass}`}>
        <animated.div className="contact__form-section" style={formSectionProps}>
          <h2>{t("Contact Title")}</h2>
          <form onSubmit={handleSubmit}>
            <div className="contact__form-row">
              <div className="contact__form-group">
                <input
                  type="text"
                  name="name"
                  required
                  onFocus={() => handleFocus('name')}
                  onBlur={(e) => handleBlur('name', e)}
                  className={focused.name ? 'contact__focused' : ''}
                />
                <label className={`${focused.name ? 'contact__focused' : ''} contact__input-title ${positionClass}`}>
                  {t("Form Label Name")}
                </label>
              </div>
              <div className="contact__form-group">
                <input
                  type="email"
                  name="email"
                  required
                  onFocus={() => handleFocus('email')}
                  onBlur={(e) => handleBlur('email', e)}
                  className={focused.email ? 'contact__focused' : ''}
                />
                <label className={`${focused.email ? 'contact__focused' : ''} contact__input-title ${positionClass}`}>
                  {t("Form Label Email")}
                </label>
              </div>
            </div>
            <div className="contact__form-group contact__message">
              <textarea
                name="message"
                placeholder={t("Form Placeholder Message")}
                required
                onFocus={() => handleFocus('message')}
                onBlur={(e) => handleBlur('message', e)}
                className={focused.message ? 'contact__message contact__focused' : 'contact__message'}
              />
              <label className={`${focused.message ? 'contact__focused' : ''} contact__input-title ${positionClass}`}>
                {t("Form Label Message")}
              </label>
            </div>
            <button type="submit">{t("Form Button")}</button>
          </form>
        </animated.div>
        <animated.div className="contact__info" style={contactInfoProps}>
          <div className="contact__desc">
            <h2>{t("Contact Title 2")}</h2>
            <p>{t("Contact Description 2")}</p>
          </div>
          <div className="contact__details">
            <p><b>{t("Contact Detail 1")} </b>{t("Contact Detail 1 Description")}</p>
            <p><b>{t("Contact Detail 2")} </b>{t("Contact Detail 2 Description")}</p>
            <p><b>{t("Contact Detail 3")} </b>{t("Contact Detail 3 Description")}</p>
          </div>
        </animated.div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
