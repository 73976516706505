import React, { memo } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import './Feature.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useTranslation } from 'react-i18next'

const AnimatedFeatureCard = memo(({ iconClass, title, description, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
        rootMargin: '100px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(30px)',
        config: config.slow,
        delay: delay,
        reset: true,
    });

    return (
        <animated.div ref={ref} style={animation} aria-labelledby={title}>
            <div className="feature-card">
                <div className="feature-card-inner">
                    <div className="feature-card-front">
                        <div className="feature-card-icon"><i className={iconClass} aria-hidden="true"></i></div>
                        <h3>{title}</h3>
                    </div>
                    <div className="feature-card-back">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </animated.div>
    );
});

AnimatedFeatureCard.propTypes = {
    iconClass: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    delay: PropTypes.number.isRequired,
};

const AnimatedTitleText = memo(({ children, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        rootMargin: '100px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        config: config.slow,
        delay: delay,
    });

    return <animated.div ref={ref} style={animation}>{children}</animated.div>;
});

AnimatedTitleText.propTypes = {
    children: PropTypes.node.isRequired,
    delay: PropTypes.number.isRequired,
};

const Feature = () => {
    const { t } = useTranslation();

    const wrapperAnimation = useSpring({
        from: { opacity: 0, transform: 'translateX(100%)' },
        to: { opacity: 1, transform: 'translateX(0%)' },
        config: { duration: 1000 },
    });

    const cardStaggerDelay = 200;

    const cards = [
        { iconClass: "fas fa-lightbulb", title: t("FeatureCard 1"), description: t("FeatureCard 1 Description") },
        { iconClass: "fas fa-globe", title: t("FeatureCard 2"), description: t("FeatureCard 2 Description") },
        { iconClass: "fas fa-shield-alt", title: t("FeatureCard 3"), description: t("FeatureCard 3 Description") },
        { iconClass: "fas fa-users", title: t("FeatureCard 4"), description: t("FeatureCard 4 Description") },
        { iconClass: "fas fa-expand-arrows-alt", title: t("FeatureCard 5"), description: t("FeatureCard 5 Description") },
        { iconClass: "fas fa-clock", title: t("FeatureCard 6"), description: t("FeatureCard 6 Description") }
    ];

    return (
        <section className="feature-section">
            <div className="feature-title-container">
                <AnimatedTitleText delay={300}>
                    <h2>{t("Feature Title")}</h2>
                </AnimatedTitleText>
                <AnimatedTitleText delay={400}>
                    <p>{t("Feature Description")}</p>
                </AnimatedTitleText>
            </div>
            <animated.div style={wrapperAnimation} className="feature-wrapper">
                <div className="feature-box-area">
                    {cards.map((card, index) => (
                        <AnimatedFeatureCard
                            key={index}
                            iconClass={card.iconClass}
                            title={card.title}
                            description={card.description}
                            delay={index * cardStaggerDelay}
                        />
                    ))}
                </div>
            </animated.div>
        </section>
    );
};

export default Feature;
