import { setItem, getItem, removeItem } from './BaseLocalStorageUtils';

const LANGUAGE_LOCAL_STORAGE_KEY = 'language';

export const setLanguage = (language) => {
  setItem(LANGUAGE_LOCAL_STORAGE_KEY, language);
};

export const getLanguage = () => {
  return getItem(LANGUAGE_LOCAL_STORAGE_KEY);
};

export const removeLanguage = () => {
  removeItem(LANGUAGE_LOCAL_STORAGE_KEY);
};
