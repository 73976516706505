import React from 'react';
import './Banner.css';
import BannerImage from '../../assets/images/homepage/platform-banner.jpg';

const Banner = () => {
    return (
        <header className="banner">
            <img src={BannerImage} alt="home-banner" className="banner__image" />
            <div className="banner__text">
                <h1 className="banner__company-name">Platform</h1>
                <p className="banner__slogan">Navigate Your Project Journey with Expertise</p>
            </div>
            <div className="banner__overlay-top"></div>
            <div className="banner__overlay-bottom"></div>
        </header>
    );
};

export default Banner;
