import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/images/platform-logo.png';
import './Navbar.css';
import LanguageSwitcher from '../../languages/components/LanguageSwitcher';

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <nav className={`navbar ${scrolled ? 'navbar__scrolled navbar__sticky' : ''}`}>
            <div className="navbar__left">
                <div className="navbar__logo"><img src={Logo} alt="Platform" /></div>
                {/* <div className="navbar__nav">
                    <a href="#home">Home</a>
                    <a href="#about">About</a>
                    <a href="#contact">Contact</a>
                </div> */}
            </div>
            <div className="navbar__right">
                {/* {!sidebarOpen && (
                    <>
                        <FontAwesomeIcon
                            icon={faMessage}
                            size="2x"
                            className="navbar__message-icon"
                        />
                        <FontAwesomeIcon
                            icon={faBars}
                            size="2x"
                            className="navbar__hamburger-icon"
                            onClick={toggleSidebar}
                        />
                    </>
                )} */}
                <LanguageSwitcher />
            </div>
            <div className={`navbar__sidebar-overlay ${sidebarOpen ? 'navbar__sidebar-overlay__open' : ''}`} onClick={closeSidebar}></div>
            <div className={`navbar__sidebar ${sidebarOpen ? 'navbar__sidebar__open' : ''}`}>
                <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    className="navbar__close-icon"
                    onClick={closeSidebar}
                />
                <div className="navbar__brand">
                    <div className="navbar__brand__logo"><img src={Logo} alt="Platform" /></div>
                    <p className="navbar__brand__text">Platform</p>
                </div>
                {/* <a href="#home" onClick={toggleSidebar}>Home</a>
                <a href="#about" onClick={toggleSidebar}>About</a>
                <a href="#contact" onClick={toggleSidebar}>Contact</a> */}
            </div>
        </nav>
    );
};

export default Navbar;
