import React from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './Management.css';

const AnimatedSection = ({ children, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
        rootMargin: '0px 0px 0px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(50px)',
        config: config.slow,
        delay: delay,
        reset: true,
    });

    return <animated.div ref={ref} style={animation} className="animated-section">{children}</animated.div>;
};

const Management = () => {
    const { t } = useTranslation();

    return (
        <section className='management-section'>
            <div className="management-content-wrapper">
                <AnimatedSection delay={0}>
                    <div className="management-container">
                        <div className="management-main">
                            <div className="center-circle">
                                <h4>{t("ManagementStep Main")}</h4>
                            </div>
                            <div className="steps">
                                <div className="circle circle-1"><div className="inner-circle">1</div></div>
                                <div className="triangle" id="step1"></div>
                                <h3 id="step-text-1">{t("ManagementStep 1")} <br />{t("ManagementStep 1-1")}</h3>

                                <div className="circle circle-2"><div className="inner-circle">2</div></div>
                                <div className="triangle" id="step2"></div>
                                <h3 id="step-text-2">{t("ManagementStep 2")}</h3>

                                <div className="circle circle-3"><div className="inner-circle">3</div></div>
                                <div className="triangle" id="step3"></div>
                                <h3 id="step-text-3">{t("ManagementStep 3")}</h3>

                                <div className="circle circle-4"><div className="inner-circle">4</div></div>
                                <div className="triangle" id="step4"></div>
                                <h3 id="step-text-4">{t("ManagementStep 4")}</h3>

                                <div className="circle circle-5"><div className="inner-circle">5</div></div>
                                <div className="triangle" id="step5"></div>
                                <h3 id="step-text-5">{t("ManagementStep 5")}</h3>

                                <div className="circle circle-6"><div className="inner-circle">6</div></div>
                                <div className="triangle" id="step6"></div>
                                <h3 id="step-text-6">{t("ManagementStep 6")}</h3>
                            </div>
                        </div>
                    </div>
                </AnimatedSection>
                <AnimatedSection delay={50}>
                    <div className="content">
                        <h2>{t("Mangement Title")}</h2>
                        <p>{t("Management Description")}</p>
                    </div>
                </AnimatedSection>
            </div>
        </section>
    );
}

export default Management;
