// src/languages/hooks/useLanguage.js
import { useState, useEffect } from 'react';
import i18n from '../i18n';
import { getLanguage as getLanguageFromLocalStorage, setLanguage as setLanguageInLocalStorage } from '../../localStorage/LanguageLocalStorageUtils';
import { getLanguageCookie as getLanguageFromCookies, setLanguageCookie as setLanguageInCookies } from '../../cookies/LanguageCookieUtils';

const supportedLanguages = ['en', 'fa'];

export const useLanguage = () => {
  const [language, setLanguageState] = useState(() => {
    const savedLanguage = getLanguageFromCookies() || getLanguageFromLocalStorage();
    
    if (savedLanguage) {
      return savedLanguage;
    } else {
      const browserLang = navigator.language.split('-')[0];
      return supportedLanguages.includes(browserLang) ? browserLang : 'en';
    }
  });

  useEffect(() => {
    i18n.changeLanguage(language);
    setLanguageInCookies(language);  // Set cookie immediately when language changes
    setLanguageInLocalStorage(language);  // Set localStorage immediately when language changes
  }, [language]);

  // Ensure the state is updated immediately
  const setLanguage = (lang) => {
    if (supportedLanguages.includes(lang)) {
      setLanguageState(lang);  // This triggers the useEffect above
    }
  };

  return [language, setLanguage];
};
