import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faHeadset, faSmile, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../languages/contexts/LanguageContext';
import { convertToPersianNumbers } from '../../utils/numberUtils';
import './Statistic.css';

const StatisticItem = ({ icon, end, description, prefix = '', suffix = '' }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { language } = useContext(LanguageContext); // Access the language context
  const { t } = useTranslation();

  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    config: { tension: 220, friction: 120 },
    delay: 300,
  });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000;
      const incrementTime = Math.abs(Math.floor(duration / end));
      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);
      return () => clearInterval(timer);
    }
  }, [end, inView]);

  const displayCount = language === 'fa' ? convertToPersianNumbers(count) : count;

  return (
    <animated.div style={animationProps} className="statistic__column" ref={ref}>
      <FontAwesomeIcon icon={icon} size="3x" className="statistic__icon" />
      <div className='statistic__count-wrapper'>
        <h2 className="statistic__count">
          {prefix}{displayCount}{suffix}
        </h2>
      </div>
      <small className="statistic__description">{description}</small>
    </animated.div>
  );
};

const Statistic = () => {
  const { t } = useTranslation();

  return (
    <div className="statistic__container">
      <h1 className="statistic__title">{t("Statistic Title")}</h1>
      <h4 className="statistic__subtitle">{t("Statistic Description")}</h4>
      <div className="statistic__row">
        <StatisticItem icon={faLaptopCode} end={10} description={t("StatisticItem 1 Text")} suffix='+' />
        <StatisticItem icon={faHeadset} end={1000} description={t("StatisticItem 2 Text")} suffix="+" />
        <StatisticItem icon={faSmile} end={98} description={t("StatisticItem 3 Text")} suffix="%" />
        <StatisticItem icon={faCodeBranch} end={3000} description={t("StatisticItem 4 Text")} suffix="+" />
      </div>
    </div>
  );
};

export default Statistic;
