import { setCookie, getCookie, removeCookie } from './BaseCookieUtils';

const LANGUAGE_COOKIE_NAME = 'language';

export const setLanguageCookie = (language) => {
  setCookie(LANGUAGE_COOKIE_NAME, language, { expires: 365 });
};

export const getLanguageCookie = () => {
  return getCookie(LANGUAGE_COOKIE_NAME);
};

export const removeLanguageCookie = () => {
  removeCookie(LANGUAGE_COOKIE_NAME);
};
