import React, { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import './Reviews.css';

const reviewItems = [
  {
    key: '1',
    name: 'Alex Khosrojerdi',
    comment: "\"Up until we worked with you, it was difficult to turn raw data into insights that could be put to use. Our decision-making process was much more efficient because of your thorough and approachable methodology.\"",
    rating: 5,
    image: require('../../assets/images/homepage/person1.jpg'),
  },
  {
    key: '2',
    name: 'Hamed Erfani',
    comment: "\"Our process was transformed by the platform you created for us. To remain at the forefront of our market, the real-time functionality and smooth user experience were crucial.\"",
    rating: 4,
    image: require('../../assets/images/homepage/person2.jpg'),
  },
  {
    key: '3',
    name: 'Armin Farhang',
    comment: "\"With your creative ideas, navigating through complex data and rules seemed natural. Where there had been confusion, your system brought clarity, and the outcomes spoke for themselves.\"",
    rating: 5,
    image: require('../../assets/images/homepage/person3.jpg'),
  },
  {
    key: '4',
    name: 'Malcolm Winters',
    comment: "\"For us, the capacity of your staff to automate difficult operations was invaluable. What used to take weeks now just takes a few seconds, with flawless execution at every step.\"",
    rating: 5,
    image: require('../../assets/images/homepage/person4.jpg'),
  },
];

const Reviews = () => {
  const { t } = useTranslation();
  const carouselRef = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(50px)',
    config: { tension: 220, friction: 120 },
    delay: 250,
    reset: true,
  });

  const renderStars = (rating) => {
    const stars = [];
    const totalStars = 5;
    for (let i = 0; i < totalStars; i++) {
      stars.push(
        <FontAwesomeIcon
          icon={i < rating ? fasStar : farStar}
          key={i}
          className={`reviews__star ${i < rating ? 'reviews__star--filled' : ''}`}
        />
      );
    }
    return stars;
  };

  const renderReviewItems = () =>
    reviewItems.map((item) => (
      <div className="reviews__item" key={item.key}>
        <div className="reviews__work">
          <div className="reviews__stars">
            {renderStars(item.rating)}
          </div>
          <div className="reviews__header">
            <div className="reviews__customer-image">
              <img src={item.image} alt={item.name} />
            </div>
            <div className="reviews__customer-name">{item.name}</div>
          </div>
          <div className="reviews__customer-comment no-lang">{item.comment}</div>
        </div>
      </div>
    ));

  return (
    <section className="reviews__section" ref={ref}>
      <animated.div style={animationProps} className="reviews__container">
        <div className="reviews__inner-container">
          <h1 className="reviews__heading">{t("Reviews Title")}</h1>
          <div className="reviews__carousel-wrapper">
            <AliceCarousel
              ref={carouselRef}
              items={renderReviewItems()}
              autoPlay
              autoPlayInterval={3000}
              animationDuration={800}
              infinite
              mouseTracking
              responsive={{
                0: { items: 1 },
                700: { items: 3 },
                1024: { items: 3 },
              }}
              paddingLeft={0}
              paddingRight={0}
              renderPrevButton={() => null}
              renderNextButton={() => null}
            />
            <div className="reviews__carousel-controls">
              <div className="reviews__carousel-dots"></div>
            </div>
          </div>
        </div>
      </animated.div>
    </section>
  );
};

export default Reviews;
