import React, { useContext } from 'react';
import Navbar from './components/navbar/Navbar';
import Banner from './components/banner/Banner';
import Feature from './components/feature/Feature';
import Management from './components/management/Management';
import Gallery from './components/reviews/Reviews';
import Statistic from './components/statistic/Statistic';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import { LanguageContext } from './languages/contexts/LanguageContext';
import './App.css';
import './utils/Toast.css';

const App = () => {
    const { language } = useContext(LanguageContext);
    
    const languageClass = language === 'fa' ? 'lang-fa' : '';

    return (
            <div className={`main-container ${languageClass}`}>
                <Navbar />
                <Banner />
                <div className='full-content-container'>
                    <Feature />
                    <Management />
                    <Gallery />
                    <Statistic />
                    <Contact />
                </div>
                <Footer />
            </div>
    );
};

export default App;
